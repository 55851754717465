.carousel .control-dots {
  width: 80%;
  left: 60px;
}

#full,
#full .carousel,
#full .slider-wrapper,
#full .slider,
#full .slide,
#full [class^='jss'],
#full [class^='makeStyles'] {
  height: 100%;
}
