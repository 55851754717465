.DraftEditor-root {
  border: 0px;
  box-sizing: border-box;
  background-color: inherit;
  cursor: text;
  padding: 10px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.DraftEditor-root:first-child {
  border-radius: inherit;
}

.DraftEditor-root :global(.public-DraftEditor-content) {
  min-height: 20px;
}

.public-DraftEditor-content {
  font-size: 14px;
}

.draftJsToolbar__toolbar__dNtBH {
  box-shadow: none !important;
  border-width: 0px 0px 1px 0px !important;
  border-style: solid !important;
  border-color: #ddd !important;
}
