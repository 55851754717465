/* edited version of node_modules/@draft-js-plugins/static-toolbar/lib/plugin.css */
.bi09khh {
    display: inline-block;
}

.bc4rxid {
    background: #fbfbfb;
    color: #888;
    font-size: 18px;
    border: 0;
    padding-top: 5px;
    vertical-align: bottom;
    height: 34px;
    width: 36px;
}

.bc4rxid:hover,
.bc4rxid:focus {
    background: #f3f3f3;
    outline: 0;
}

.bc4rxid svg {
    fill: #888;
}

.akzb7t5 {
    background: #efefef;
    color: #444;
}

.akzb7t5 svg {
    fill: #444;
}

.t16lpgj {
    border: 1px solid #ddd;
    background: inherit;
    border-radius: 2px;
    box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
    z-index: 2;
    box-sizing: border-box;
}

.t16lpgj:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 4px;
    margin-left: -4px;
}

.t16lpgj:before {
    border-color: rgba(221, 221, 221, 0);
    border-top-color: #ddd;
    border-width: 6px;
    margin-left: -6px;
}

.s6m29i4 {
    display: inline-block;
    border-right: 1px solid #ddd;
    height: 24px;
    margin: 0 0.5em;
}

/* overrides */

.t16lpgj { /* toolbar container */
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    border-radius: inherit;
}

.wyswyg-container-dfgewertg { /* wyswyg-container */
    border: 1px solid;
    border-radius: 4px;
    padding: 0px 0px;
    width: 100%;
    background: #fff;
}